import { Middleware } from '@nuxt/types';

const middleware: Middleware = (context) => {
  const queryParams = context.route.query;
  // 接收google 授权后后端返回token
  if (queryParams && queryParams?.token) {
    const token: string = queryParams?.token.toString();
    context.app.$vsf.$magento.config.state.setCustomerToken(token);
  } else if (!context.app.$vsf.$magento.config.state.getCustomerToken()) {
    context.app.$vsf.$magento.config.state.setMessage({
      id: Symbol('no_login_info'),
      message: context.app.i18n.t('Please login firstly') as string,
      persist: false,
      type: 'info',
      icon: 'info',
    });
    context.app.router.push(context.app.localePath('/user/signin'));
  }
};
export default middleware;
