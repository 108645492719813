





























































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import { SfButton, SfCheckbox, SfInput, SfSelect } from '@storefront-ui/vue';
import omitDeep from 'omit-deep';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { min, oneOf, required } from 'vee-validate/dist/rules';
import { PropType } from 'vue';

import type { Countries, Country, UseCountrySearchParams } from '~/composables';
import { CountryCodeEnum, useCountrySearch } from '~/composables';
import type { TransformedCustomerAddress } from '~/modules/account/composables/types';
import addressGetter from '~/modules/account/getters/addressGetter';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('oneOf', {
  ...oneOf,
  message: 'Invalid country',
});

export default defineComponent({
  name: 'AddressForm',

  components: {
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    AddressCountrySelector: () =>
      import('~/components/AddressCountrySelector.vue'),
  },

  props: {
    address: {
      type: Object as PropType<TransformedCustomerAddress>,
      default: () => ({
        // apartment: '',
        city: '',
        country_code: '' as CountryCodeEnum,
        firstname: '',
        lastname: '',
        postcode: '',
        region: {
          region_code: '',
          region_id: 0,
        },
        street: '',
        telephone: '',
        default_shipping: false,
        default_billing: false,
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { load: loadCountries, search: searchCountry } = useCountrySearch();

    const form = ref<TransformedCustomerAddress | null>(null);

    const countries = ref<Countries[]>([]);
    const countriesList = computed(() =>
      addressGetter.countriesList(countries.value)
    );
    const country = ref<Country | null>(null);
    const regionInformation = computed(() =>
      addressGetter.regionList(country.value)
    );

    const updateCountry = async (params: UseCountrySearchParams) => {
      country.value = await searchCountry(params);
      console.log('country---1---select', country);
      form.value.region = { region_id: null, region_code: '' };
    };
    const handleSelectCountry = async (selectCountry) => {
      form.value.country_code = selectCountry.id;
      country.value = await searchCountry({ id: selectCountry.id });
      console.log('country---2---component', country);
      form.value.region = { region_id: null, region_code: '' };
    };

    watch(
      () => props.address,
      () => {
        form.value = {
          // apartment: props.address.apartment,
          city: props.address.city,
          country_code: props.address.country_code,
          firstname: props.address.firstname,
          lastname: props.address.lastname,
          postcode: props.address.postcode,
          region: {
            region_code: '',
            region_id: null,
            ...props.address.region,
          },
          street: props.address.street,
          telephone: props.address.telephone,
          default_shipping: props.address.default_shipping || false,
          default_billing: props.address.default_billing || false,
        } as TransformedCustomerAddress;
      },
      { immediate: true }
    );

    const submitForm = () => {
      const regionId = regionInformation.value.find(
        (r) => r.abbreviation === form.value.region.region_code
      )?.id;
      form.value.region = regionId
        ? { region_id: regionId, region_code: form.value.region.region_code }
        : {
            ...form.value.region,
            region_code: '',
            region_id: null,
          };

      emit('submit', {
        form: omitDeep(form.value, ['__typename']),
        // TODO: Handle Error
        onError: () => {},
      });
    };

    onBeforeMount(async () => {
      countries.value = await loadCountries();
      if (props.address.country_code) {
        country.value = await searchCountry({ id: props.address.country_code });
      }
    });
    return {
      form,
      submitForm,
      countries,
      countriesList,
      regionInformation,
      updateCountry,
      handleSelectCountry,
    };
  },
});
