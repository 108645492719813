












import { defineComponent, useRoute } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';

import AppHeader from '~/components/AppHeader.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'LoginLayout',

  components: {
    LazyHydrate,
    AppHeader,
    IconSprite,
    Notification: () =>
      import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
    } = useUiState();

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
    };
  },
  head: {
    // link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
  },
});
