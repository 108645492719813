import { readonly, ref, useContext } from '@nuxtjs/composition-api';

import { Logger } from '~/helpers/logger';
import useCart from '~/modules/checkout/composables/useCart';
import type { Cart, ShippingCartAddress } from '~/modules/GraphQL/types';

import type { UseShippingErrors, UseShippingInterface, UseShippingLoadParams, UseShippingSaveParams } from './useShipping';

/**
 * Allows loading the shipping information for
 * the current cart and saving (selecting) other shipping information for the
 * same cart.
 *
 * See the {@link UseShippingInterface} for a list of methods and values available in this composable.
 */
export function useShipping(): UseShippingInterface {
  const loading = ref(false);
  const error = ref<UseShippingErrors>({ load: null, save: null });
  const { cart, load: loadCart, loading: isLoadingCart, setCart } = useCart();
  const { app } = useContext();

  const load = async (params: UseShippingLoadParams = {}): Promise<ShippingCartAddress | null> => {
    Logger.debug('useShipping.load');
    let shippingInfo: any | null = null;

    try {
      loading.value = true;
      if (cart?.value?.shipping_addresses.length === 0) {
        await loadCart(params);
      }
      [shippingInfo] = cart.value.shipping_addresses;
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useShipping/load error: ', err);
    } finally {
      loading.value = false;
    }
    return shippingInfo;
  };

  const save = async ({ shippingDetails }: UseShippingSaveParams): Promise<ShippingCartAddress | null> => {
    Logger.debug('useShipping.save:', { shippingDetails });
    let shippingInfo: ShippingCartAddress | null = null;

    try {
      loading.value = true;
      error.value.save = null;

      const { id } = cart.value;

      const { apartment, neighborhood, extra, customerAddressId, ...address } = shippingDetails;
      if (address && (address.region_name || address.region_name === '')) {
        delete address.region_name;
      }

      const shippingData = customerAddressId
        ? { customer_address_id: customerAddressId }
        : {
            address: {
              ...address,
              street: [address.street, apartment, neighborhood, extra].filter(Boolean),
            },
          };

      const shippingAddressInput = {
        cart_id: id,
        shipping_addresses: [
          {
            ...shippingData,
          },
        ],
      };
      const { data, errors } = await app.$vsf.$magento.api.setShippingAddressesOnCart(shippingAddressInput, {
        setShippingAddressesOnCart: 'cart-set-shipping-addresses',
      });

      Logger.debug('[SetShippingAddressesOnCart Result]:', { data, errors });
      if ((errors && errors.length > 0) || !data) {
        throw new Error('Errors withh setting shipping address');
      }
      const cartData: any = data.setShippingAddressesOnCart.cart;
      if (cartData) {
        setCart({
          ...cart.value,
          shipping_addresses: cartData.shipping_addresses,
          available_payment_methods: cartData.available_payment_methods,
          prices: cartData.prices,
        });
        // setCart(data.setShippingAddressesOnCart.cart as unknown as Cart);
      }

      [shippingInfo] = data.setShippingAddressesOnCart.cart.shipping_addresses;
      // if (cart?.value?.id) {
      //   await loadCart();
      // }

      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      Logger.error('useShipping/save', err);
    } finally {
      loading.value = false;
    }

    return shippingInfo;
  };

  return {
    load,
    save,
    error: readonly(error),
    loading: readonly(loading && isLoadingCart),
  };
}

export * from './useShipping';
export default useShipping;
